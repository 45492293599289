import react, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../stor/AuthProvider';
import { UserRole } from '../../../api/auth';
import { getModel } from '../../../api/model';
import { AlertContext } from '../../../stor/AlertProvider';
import { CloseSvg } from '../../../static/svg/CloseSvg';


interface ICreateAdvertisementWindowProperties {
    onExit: () => void;
}

export const CreateAdvertisementWindow = ({ onExit }: ICreateAdvertisementWindowProperties) => {
    const ref = useRef<HTMLDivElement>();

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const [nickname, setNickname] = useState<string>('');

    const onSubmit = useCallback(async () => {
        try {
            let nm: string = '';

            try {
                new URL(nickname);

                const r = nickname.split('/');
                while (0 < r.length && nm.length == 0) {
                    nm = r.pop();
                }
            }
            catch {
                nm = (nickname.charAt(0) != '@')
                    ? nickname
                    : nickname.slice(1, nickname.length);
            }

            const m = await getModel(nm, user.bear);

            navigate(`/${user.role == UserRole.CLIENT ? 'user' : 'root'}/advertisement/create?nickname=${m.nickname}`);
        }
        catch (error) {
            console.error(error);
            setAlertMessage(String(error));
        }
    }, [nickname]);

    useEffect(() => {
        const body = document.querySelector('body');

        const workMouseDown = (e) => {
            if (!ref || !!alertMessage || !!ref.current.contains(e['target'])) {
                return;
            }

            e['preventDefault']();
            onExit();
        }

        const workEnterPress = (e) => {
            if (!ref || !!alertMessage || e['key'] != 'Enter') {
                return;
            }

            e['preventDefault']();
            onSubmit();
        }

        body.classList.add('lock');
        document.addEventListener('mousedown', workMouseDown);
        document.addEventListener('keypress', workEnterPress);

        return () => {
            document.removeEventListener('keypress', workEnterPress);
            document.removeEventListener('mousedown', workMouseDown);
            body.classList.remove('lock');
        }
    }, [ref, alertMessage, onSubmit]);

    return (
        <div className='create-add-popup popup popup-active'>
            <div className='create-add-popup__body popup__body' ref={ref}>
                <div className='create-add-popup__title popup__title title__small'>
                    Create Advertisement
                </div>
                <div
                    className='create-add-popup__close popup__close close-popup'
                    onClick={() => onExit()}
                >
                    <CloseSvg />
                </div>
                <div className='create-add-popup__wrapper popup__wrapper'>

                    <div className='create-add-popup__input top-animation'>
                        <input
                            type='text'
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}
                        />
                        <label>
                            OnlyFans Account Nickname
                        </label>
                    </div>

                </div>
                <div className='create-add-popup__buttons popup__buttons'>
                    <button
                        className='create-add-popup__btn-ok popup__btn-ok'
                        onClick={(e) => {
                            e.preventDefault();
                            onSubmit();
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}