import React, { useContext, useEffect, useState } from 'react';
import { Paging } from '../../common/Paging';
import { IModel, getModelList } from '../../../api/model';
import { ReadSvg } from '../../../static/svg/ReadSvg';
import { UpdateSvg } from '../../../static/svg/UpdateSvg';
import { DeleteSvg } from '../../../static/svg/DeleteSvg';
import { CreateSvg } from '../../../static/svg/CreateSvg';
import { AuthContext } from '../../../stor/AuthProvider';
import { ReadRootModelWindow } from './ReadRootModelWindow';
import { UpdateRootModelWindow } from './UpdateRootModelWindow';
import { CreateRootModelWindow } from './CreateRootModelWindow';
import { DeleteRootModelWindow } from './DeleteRootModelWindow';
import { dateToString } from '../../../libs/date';
import { AlertContext } from '../../../stor/AlertProvider';
import { RefreshSvg } from '../../../static/svg/RefreshSvg';
import { RefreshRootModelWindow } from './RefreshRootModelWindow';


interface IRootModelTableRowProperties {
    model: IModel;
    onReadRequest: (model: IModel) => void;
    onUpdateRequest: (model: IModel) => void;
    onRefreshRequest: (model: IModel) => void;
    onDeleteRequest: (model: IModel) => void;
}

const RootModelTableRow = ({ model, onReadRequest, onUpdateRequest, onRefreshRequest, onDeleteRequest }: IRootModelTableRowProperties) => {
    const onRead = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onReadRequest(model);
    }

    const onUpdate = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onUpdateRequest(model);
    }

    const onRefresh = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onRefreshRequest(model);
    }

    const onDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onDeleteRequest(model);
    }

    return (
        <div className='table__row'>
            <div className='table__item actions'>
                <button className='actions__view popup-link' onClick={onRead}>
                    <ReadSvg />
                </button>
                <button className='actions__edit popup-link' onClick={onUpdate}>
                    <UpdateSvg />
                </button>
                <button className='actions__refresh' onClick={onRefresh}>
                    <RefreshSvg />
                </button>
                <button className='actions__delete popup-link' onClick={onDelete}>
                    <DeleteSvg />
                </button>
            </div>
            <div className='table__item avatar'>
                <img src={model.avatar} />
            </div>
            <div className='table__item nickname'>
                {model.nickname}
            </div>
            <div className='table__item create-date'>
                {dateToString(model.create_moment)}
            </div>
            <div className='table__item twitter-link'>
                {
                    !!model.twitter &&
                    <a target='_blank' href={`https://twitter.com/${model.twitter}`}>
                        <span className='twitter-link__span'>Link</span>
                    </a>
                }
                {
                    !model.twitter &&
                    <span className='twitter-link__span'>None</span>
                }
            </div>
            <div className='table__item tiktok-link'>
                {
                    !!model.tiktok &&
                    <a target='_blank' href={`https://www.tiktok.com/${model.tiktok}`}>
                        <span className='tiktok-link__span'>Link</span>
                    </a>
                }
                {
                    !model.tiktok &&
                    <span className='tiktok-link__span'>None</span>
                }
            </div>
            <div className='table__item instagram-link'>
                {
                    !!model.instagram &&
                    <a target='_blank' href={`https://www.instagram.com/${model.instagram}`}>
                        <span className='instagram-link__span'>Link</span>
                    </a>
                }
                {
                    !model.instagram &&
                    <span className='instagram-link__span'>None</span>
                }
            </div>
        </div>
    );
}

interface IRootModelTableProperties {
    modelList: IModel[];
    onCreateRequest: () => void;
    onReadRequest: (model: IModel) => void;
    onUpdateRequest: (model: IModel) => void;
    onRefreshRequest: (model: IModel) => void;
    onDeleteRequest: (model: IModel) => void;
}

const RootModelTable = ({ modelList, onCreateRequest, onReadRequest, onUpdateRequest, onRefreshRequest, onDeleteRequest }: IRootModelTableProperties) => {
    return (
        <div className='admin-models__table table'>
            <div className='table__row row-table-first'>
                <div className='table__item actions'>Actions</div>
                <div className='table__item avatar'>Avatar</div>
                <div className='table__item nickname'>Nickname</div>
                <div className='table__item create-date'>Date</div>
                <div className='table__item twitter-link'>Twitter</div>
                <div className='table__item tiktok-link'>Tiktok</div>
                <div className='table__item instagram-link'>Instagram</div>
            </div>
            <button
                className='table__create-admin-adv create-btn popup-link'
                onClick={(e) => {
                    e.preventDefault();
                    onCreateRequest();
                }}
            >
                <CreateSvg />
            </button>
            {
                modelList.map((model) => {
                    return (
                        <RootModelTableRow
                            key={model.nickname}
                            model={model}
                            onReadRequest={onReadRequest}
                            onUpdateRequest={onUpdateRequest}
                            onRefreshRequest={onRefreshRequest}
                            onDeleteRequest={onDeleteRequest}
                        />
                    );
                })
            }
        </div>
    );
}

interface IRequest {
    method: 'CREATE' | 'READ' | 'UPDATE' | 'REFRESH' | 'DELETE';
    model?: IModel;
}

export const RootModel = () => {
    const { setAlertMessage } = useContext(AlertContext);

    const pageSize = 10;

    const [modelList, setModelList] = useState<IModel[]>([]);

    const [page, setPage] = useState<number>(1);

    const [request, setRequest] = useState<IRequest>();

    const { user } = useContext(AuthContext);

    useEffect(() => {
        const work = async () => {
            try {
                const r = await getModelList(user.bear);
                setModelList(r);
            }
            catch (error) {
                console.error(error);
                setAlertMessage(String(error));   
            }
        }

        work();
    }, []);

    const onCreateRequest = () => {
        setRequest({method: 'CREATE'});
    }

    const onReadRequest = (model: IModel) => {
        setRequest({method: 'READ', model: model});
    }

    const onUpdateRequest = (model: IModel) => {
        setRequest({method: 'UPDATE', model: model});
    }

    const onRefreshRequest = (model: IModel) => {
        setRequest({method: 'REFRESH', model: model});
    }

    const onDeleteRequest = (model: IModel) => {
        setRequest({method: 'DELETE', model: model});
    }

    const onExit = () => {
        setRequest(null);
    }

    const onCreate = (model: IModel) => {
        setModelList([model, ...modelList]);
        setRequest(null);
    }

    const onUpdate = (model: IModel) => {
        setModelList(modelList.map((m) => m.nickname != model.nickname ? m : model));
        setRequest(null);
    }

    const onRefresh = (model: IModel) => {
        setModelList(modelList.map((m) => m.nickname != model.nickname ? m : model));
        setRequest(null);
    }

    const onDelete = (model: IModel) => {
        setModelList(modelList.filter((m) => m.nickname != model.nickname));
        setRequest(null);
    }

    return (
        <>
            <section className='admin-models'>
                <div className='admin-models__container'>
                    <h2 className='admin-models__title title'>
                        Model List
                    </h2>
                    <RootModelTable
                        modelList={modelList.slice((page - 1) * pageSize, page * pageSize)}
                        onCreateRequest={onCreateRequest}
                        onReadRequest={onReadRequest}
                        onUpdateRequest={onUpdateRequest}
                        onRefreshRequest={onRefreshRequest}
                        onDeleteRequest={onDeleteRequest}
                    />
                    <div className='admin-models__paging paging'>
                        <Paging
                            page={page}
                            size={Math.ceil(modelList.length / pageSize)}
                            setPage={(p) => setPage(p)}
                        />
                    </div>
                </div>
            </section>
            {
                !!request && request.method == 'CREATE' &&
                <CreateRootModelWindow onCreate={onCreate} onExit={onExit} />
            }
            {
                !!request && request.method == 'READ' &&
                <ReadRootModelWindow model={request.model} onExit={onExit} />
            }
            {
                !!request && request.method == 'UPDATE' &&
                <UpdateRootModelWindow inp={request.model} onUpdate={onUpdate} onExit={onExit} />
            }
            {
                !!request && request.method == 'REFRESH' &&
                <RefreshRootModelWindow model={request.model} onRefresh={onRefresh} onExit={onExit} />
            }
            {
                !!request && request.method == 'DELETE' &&
                <DeleteRootModelWindow model={request.model} onDelete={onDelete} onExit={onExit} />
            }
        </>
    );
}